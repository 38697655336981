html, body {
  background: #000;
  width: 100vw;
  height: 100vh;
  margin: 0;
  position: relative;
  overflow: hidden;
}

#canvas {
  width: 100vw;
  height: 100vh;
  display: block;
}

#kotlovan {
  z-index: 200;
  width: 65vw;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#kotlovan img {
  width: 100%;
  height: auto;
}

#kotlovan-wrapper {
  border: 10px dotted #ccc;
  position: fixed;
  inset: 5px;
}
/*# sourceMappingURL=index.be13cf59.css.map */
