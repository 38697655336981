html,
body {
    position: relative;
    width: 100vw;
    height: 100vh;
    margin: 0;
    background: #000;
    overflow: hidden;
}

#canvas {
    display: block;
    height: 100vh;
    width: 100vw;
}

#kotlovan {
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
    width: 65vw;
    z-index: 200;
}

#kotlovan img {
    width: 100%;
    height: auto;
}

#kotlovan-wrapper {
    position: fixed;
    top: 5px;
    left: 5px;
    bottom: 5px;
    right: 5px;
    border: 10px dotted #cccccc;
}